var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',[_c('v-row',[_c('v-checkbox',{attrs:{"name":"minOn","input-value":_vm.minOn},on:{"click":_vm.updateMinOn}}),_c('date-field',{attrs:{"label":_vm.$t('pages.form.question.min_date'),"disabled":!_vm.minOn,"rules":[
        (v) => !_vm.minOn || _vm.isValid(v) || _vm.$t('errors.invalid_date'),
        (v) => !_vm.minOn || !_vm.maxOn || _vm.isBefore(v, this.question.max)
          || _vm.$t('errors.date_min_max'),
      ]},on:{"input":function($event){return _vm.dateInput('min', $event)}},model:{value:(_vm.question.min),callback:function ($$v) {_vm.$set(_vm.question, "min", $$v)},expression:"question.min"}})],1),_c('v-row',[_c('v-checkbox',{attrs:{"name":"maxOn","input-value":_vm.maxOn},on:{"change":_vm.updateMaxOn}}),_c('date-field',{attrs:{"label":_vm.$t('pages.form.question.max_date'),"disabled":!_vm.maxOn,"rules":[
        (v) => !_vm.maxOn || _vm.isValid(v) || _vm.$t('errors.invalid_date'),
        (v) => !_vm.minOn || !_vm.maxOn || _vm.isBefore(this.question.min, v)
          || _vm.$t('errors.date_max_min'),
      ]},on:{"input":function($event){return _vm.dateInput('max', $event)}},model:{value:(_vm.question.max),callback:function ($$v) {_vm.$set(_vm.question, "max", $$v)},expression:"question.max"}})],1),_c('v-row',[_c('v-checkbox',{attrs:{"label":_vm.$t('pages.form.question.update_date', { event: _vm.$t('common.messages.term') }),"name":"updateTerm"},model:{value:(_vm.question.updateTerm),callback:function ($$v) {_vm.$set(_vm.question, "updateTerm", $$v)},expression:"question.updateTerm"}})],1),_c('v-row',[_c('v-checkbox',{attrs:{"label":_vm.$t('pages.form.question.update_date', { event: _vm.$t('common.messages.delivery') }),"name":"updateTerm"},model:{value:(_vm.question.updateDelivery),callback:function ($$v) {_vm.$set(_vm.question, "updateDelivery", $$v)},expression:"question.updateDelivery"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }