<template>
  <v-col class="pt-0">
    <v-row>
      <v-col>
        <v-row
          class="pa-0 text-body-1" justify="space-between" style="cursor: pointer"
          @click.stop="choicesExpanded = !choicesExpanded"
        >
          <v-col class="pa-0">{{ $t('common.messages.possible_choices') }}</v-col>
          <v-icon>{{ choicesExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-row>

        <v-row class="mt-2">
          <v-divider/>
        </v-row>

        <v-expand-transition>
          <v-row v-show="choicesExpanded">
            <v-col>
              <v-row v-if="!externalChoices && question.choices != null">
                <choice-collection
                  :choices="question.choices"
                  @update="$set(question, 'choices', $event)"
                />
              </v-row>

              <v-row v-if="externalChoices">
                <choice-collection-select v-model="question.choiceCollectionId" />
              </v-row>
            </v-col>
          </v-row>
        </v-expand-transition>

        <v-row
          style="margin-bottom: -24px;"
        >
          <v-switch
            v-bind:label="$t('pages.form.question.use_external_choices')"
            :input-value="externalChoices"
            @change="toggleExternalChoices"
          />
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-checkbox
        v-bind:label="$t('pages.form.question.multi_selection')"
        v-model="question.multipleSelection"
        input-value="true"
      />
    </v-row>

    <v-row>
      <v-checkbox
        v-bind:label="$t('pages.form.question.other_active')"
        v-model="question.otherChoice"
        input-value="true"
      />
    </v-row>

    <v-row>
      <v-checkbox
        v-bind:label="$t('pages.form.question.none_active')"
        v-model="question.noneChoice"
      />
    </v-row>
  </v-col>
</template>

<script>
import ChoiceCollection from '@/components/choicecollection/ChoiceCollection.vue';
import ChoiceCollectionSelect from '@/components/choicecollection/ChoiceCollectionSelect.vue';

export default {
  name: 'multiple-choice-question',
  components: { ChoiceCollectionSelect, ChoiceCollection },
  props: {
    question: { type: Object, required: true },
    showChoices: { type: Boolean, default: true },
  },
  data: () => ({
    choicesExpanded: false,
    choices: [],
    choiceCollectionId: null,
  }),
  computed: {
    externalChoices() {
      return this.question.choices === null;
    },
  },
  methods: {
    toggleExternalChoices(v) {
      this.choicesExpanded = true;
      if (v) {
        this.choices = this.question.choices;
        this.$set(this.question, 'choices', null);
        this.$set(this.question, 'choiceCollectionId', this.choiceCollectionId);
      } else {
        this.choiceCollectionId = this.question.choiceCollectionId;
        this.$set(this.question, 'choiceCollectionId', null);
        this.$set(this.question, 'choices', this.choices);
      }
    },
  },
  created() {
    this.choices = this.question.choices || [];
    this.choiceCollectionId = this.question.choiceCollectionId;
  },
};
</script>
