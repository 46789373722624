<template>
  <v-dialog
    v-model="open"
    max-width="1080"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-container class="pt-0">
        <v-row align="center">
          <v-col class="py-0">
            <v-row v-if="candidate">
              {{label}}
            </v-row>
            <v-row v-else>
              {{ $t('common.messages.choose_question') }}
            </v-row>

            <v-row v-if="candidate" class="pa-0" align="center">
              <v-col class="pa-0">
                <v-simple-table style="width: 100%">
                  <tbody>
                  <tr class="mx-0 no-hover-color">
                    <td>{{ candidate.id }}</td>
                    <td>{{ candidate.label }}</td>
                    <td>{{ candidate.variable }}</td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </v-col>

              <v-btn
                depressed
                v-on="on"
                v-bind="attrs"
              >
                {{ $t('common.action.change') }}
              </v-btn>
            </v-row>
          </v-col>

          <v-btn
            v-if="!candidate"
            depressed
            v-on="on"
            v-bind="attrs"
          >
            {{ $t('common.action.choose') }}
          </v-btn>
        </v-row>
      </v-container>
    </template>

    <v-card
      :loading="isLoading"
    >
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>
          {{ $t('common.messages.choose_question') }}
        </v-toolbar-title>

        <v-spacer/>

        <v-btn
          icon
          depressed
          @click="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="mt-2">
        {{ explanation }}
      </v-card-text>

      <v-card-text>
        <div style="font-size: large">{{ $t('common.words.filters') }}</div>
        <v-text-field
          v-bind:label="$t('common.labels.wording')"
          v-model="filter.label"
        />
        <v-text-field
          v-bind:label="$t('common.labels.variable')"
          v-model="filter.variable"
        />
        <v-btn
          depressed
          color="primary"
          @click="search"
        >
          {{ $t('common.action.search') }}
        </v-btn>
      </v-card-text>

      <v-card-text>
        <candidate-table
          :candidates="candidates"
          :current="candidate ? candidate.id : null"
          :loading="isLoading"
          @selected="select"
        />

        <paginator
          v-if="fetchMixin.pagination"
          :pagination="fetchMixin.pagination"
          @update="updatePagination"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CandidateTable from '@/components/forms/components/candidate/CandidateTable.vue';
import VInput from 'vuetify/lib/components/VInput/VInput';
import Paginator from '@/components/util/paginator.vue';
import FetchMixin from '@/mixins/fech.mixin';
import ComponentService from '@/services/api/component.service';

export default {
  name: 'group-candidate-selection',
  extends: VInput,
  mixins: [FetchMixin],
  components: { Paginator, CandidateTable },
  props: {
    questionId: { type: Number, default: null },
    explanation: { type: String, require: true },
    filter: { type: Object, require: true },
  },
  data: () => ({
    open: false,
    candidate: null,
    candidates: [],
    userFilter: {
      label: '', variable: '',
    },
  }),
  computed: {
    hasError() {
      return !this.candidate;
    },
  },
  methods: {
    select(candidate) {
      this.candidate = candidate;
      this.$emit('selected', candidate);
      this.close();
    },
    search() {
      this.pagination = null;
      this.fetch();
    },
    getAllQuestion() {
      const { size, page } = this.pagination || { page: 1, size: 10 };
      return ComponentService.queryComponent({ ...this.filter, ...this.userFilter }, { size, page });
    },
    getQuestionById(id) {
      return ComponentService.fetchComponent(id);
    },
    close() {
      this.open = false;
    },
  },
  inject: {
    form: { default: null },
  },
  created() {
    if (this.form) this.form.register(this);

    this.fetchMixin.config = {
      fetch: 'getAllQuestion',
      dataField: 'candidates',
      fetchById: 'getQuestionById',
      dataFieldOne: 'candidate',
    };

    if (this.questionId !== null) {
      this.fetchById(this.questionId);
    }
    this.fetch();
  },
  beforeDestroy() {
    if (this.form) this.form.unregister(this);
  },
};
</script>

<style scoped>
.no-hover-color:hover {
  background-color: transparent !important;
}
</style>
