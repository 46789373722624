<template>
  <v-simple-table>
    <thead>
    <tr>
      <th class="text-center">{{ $t('common.labels.id') }}</th>
      <th class="text-left">{{ $t('common.labels.wording') }}</th>
      <th class="text-left">{{ $t('common.labels.variable') }}</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr
      v-for="candidate in candidates" :key="candidate.id"
      @click="select(candidate)" :class="candidate.id !== current ? 'pointer' : ''"
    >
      <td class="text-center">{{ candidate.id}}</td>

      <td class="text-left">{{ candidate.label }}</td>

      <td class="text-left">{{ candidate.variable }}</td>

      <td class="text-right" v-if="candidate.id === current">
        <v-chip label color="success">
          {{ $t('common.messages.current_selection') }}
        </v-chip>
      </td>
      <td class="text-right" v-else>
        <v-btn
          depressed color="primary" @click.stop="select(candidate)"
          :loading="loading" :disabled="loading"
        >
          {{ $t('common.action.choose') }}
        </v-btn>
      </td>
    </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: 'candidate-table',
  props: {
    candidates: { type: Array, required: true },
    current: { type: Number, default: null },
    loading: { type: Boolean, default: false },
  },
  methods: {
    select(candidate) {
      if (this.current === candidate.id) return;

      this.$emit('selected', candidate);
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
