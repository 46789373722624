<template>
  <v-form ref="form" style="width: 100%" v-model="formValid">
    <v-col>
      <v-row class="text-h6">
        {{ $t('common.action.add') }} {{ $t('common.messages.a_new_fem') }} {{ $t('common.messages.branching_logic') }}
      </v-row>

      <v-row v-if="answers">
        <v-select
          v-bind:label="$t('common.labels.if_answer')"
          :items="answers"
          item-value="answer"
          v-model="selectedAnswer"
          :rules="[(v) => v != null || $t('errors.choose_in_list', {name: $t('common.words.manswer')})]"
        />
      </v-row>

      <v-row>
        <v-select
          v-bind:label="$t('common.labels.execute_action')"
          :items="actionItems"
          v-model="selectedAction"
          :rules="[(v) => v != null || $t('errors.choose_in_list', {name: $t('common.words.maction')})]"
        />
      </v-row>

      <v-row v-if="selectedAction && selectedAction.type === END_RESEARCH">
        <form-select v-model="formId"/>
      </v-row>

      <v-row justify="space-between" align="center">
        <v-spacer/>
        <v-btn
          depressed color="primary" @click="createRule"
          :disabled="!formValid || createMixin.loading" :loading="isLoading"
        >
          {{ $t('common.action.add') }}
        </v-btn>
      </v-row>
    </v-col>
  </v-form>
</template>

<script>
import BranchingLogicService from '@/services/api/branchinglogic.service';
import CreateMixin from '@/mixins/create.mixin';
import FormSelect from '@/components/forms/FormSelect.vue';

const NEXT_COMPONENT = 'NEXT_COMPONENT';
const END_FORM = 'END_FORM';
const DELAY_FORM = 'DELAY_FORM';
const END_RESEARCH = 'END_RESEARCH';

export default {
  name: 'branching-logic-form',
  components: { FormSelect },
  mixins: [CreateMixin],
  props: {
    component: { type: Object, required: true },
    components: { default: null },
    answers: { default: null },
    excludeTypes: { default: () => [] },
  },
  data: () => ({
    NEXT_COMPONENT,
    END_FORM,
    DELAY_FORM,
    END_RESEARCH,
    formValid: false,
    selectedAnswer: null,
    selectedAction: null,
    formId: null,
  }),
  computed: {
    actionItems() {
      let items = [];
      if (!this.isTypeExcluded(NEXT_COMPONENT)) {
        items = [
          ...this.components
            .filter((component) => component.id !== this.component.id
              && component.position > this.component.position)
            .map((component) => {
              const value = {
                type: NEXT_COMPONENT,
                nextComponentId: component.id,
              };

              const text = component.type.includes('GROUP')
                ? `${this.$t('common.messages.go_to')} ${this.$t(`component.${component.type}.title`).toLowerCase()} #${component.id}`
                : `${this.$t('common.messages.go_to_question')}${component.id}: ${component.label}`;

              return { text, value };
            }),
        ];
      }

      if (!this.isTypeExcluded(DELAY_FORM)) {
        items.push({
          text: this.$t('common.messages.delay_form'),
          value: { type: DELAY_FORM },
        });
      }

      if (!this.isTypeExcluded(END_FORM)) {
        items.push({
          text: this.$t('common.messages.end_form'),
          value: { type: END_FORM },
        });
      }

      if (!this.isTypeExcluded(END_RESEARCH)) {
        items.push({
          text: this.$t('common.messages.end_research_simple'),
          value: { type: END_RESEARCH },
        });
      }

      return items;
    },
  },
  methods: {
    isTypeExcluded(type) {
      return this.excludeTypes.includes(type);
    },
    createRule() {
      this.$refs.form.validate();
      if (!this.formValid) return;

      this.create();
      // this.selectedAction = null;
      // this.selectedAnswer = null;
      // this.$refs.form.reset();
    },
    createBranchingLogic() {
      const rule = {
        ...this.selectedAction,
        questionId: this.component.id,
        answerData: this.selectedAnswer,
        formId: this.formId,
      };

      return BranchingLogicService.createBranchingLogic(rule);
    },
  },
  created() {
    this.createMixin.config = {
      create: 'createBranchingLogic',
    };
  },
};
</script>
