<template>
  <div v-if="fetchMixin.state === fetchMixin.STATES.ERROR"></div>
  <div v-else>
    <v-card flat tile :loading="fetchMixin.state === fetchMixin.state.LOADING">
      <v-card-title class="text-h4">
        <v-row no-gutters>
          <v-col>
            {{ $t('pages.form_info.title') }} #{{ id }}
          </v-col>

          <!-- Push form -->
          <confirm-button width="400px" @click="pushForm(form.id)">
            <template v-slot:button="{ on, attrs }">
              <v-btn
                icon color="primary"
                v-on="on" v-bind="attrs"
              >
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </template>

            <template v-slot:title>
              {{ $t('pages.form_info.push_form_1') }}<br/>
              {{ $t('pages.form_info.push_form_2') }}
            </template>

            <template v-slot:message>
              {{ $t('pages.form_info.push_form_3') }}
            </template>
          </confirm-button>

          <v-btn
            text color="primary"
            @click="$router.push('/forms')"
          >
            {{ $t('common.action.back') }}
          </v-btn>
        </v-row>
      </v-card-title>

      <v-card-text>
        <form-creation-form :value="form" @updated="updateFormCallback"/>
      </v-card-text>
    </v-card>
    <component-list :parentId="id" parentType="FORM"/>
  </div>
</template>

<script>
import FormService from '@/services/api/form.service';
import FormCreationForm from '@/components/forms/creation/FormSaveForm.vue';
import FetchMixin from '@/mixins/fech.mixin';
import ComponentList from '@/components/forms/components/ComponentList.vue';
import FlashUtils from '@/utils/flash.utils';
import ConfirmButton from '@/components/util/ConfirmButton.vue';

export default {
  name: 'form-info',
  mixins: [FetchMixin],
  components: {
    ConfirmButton,
    FormCreationForm,
    ComponentList,
  },
  props: {
    formProp: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: null,
  }),
  computed: {
    id() {
      return parseInt(this.$route.params.formId, 10);
    },
  },
  methods: {
    updateFormCallback(formUpdated) {
      this.form = { ...this.forms, formUpdated };
    },
    fetchForm(id) {
      return FormService.fetchForm(id);
    },
    async pushForm(id) {
      try {
        await FormService.pushForm(id);
        FlashUtils.sendSuccess('Le formulaire a été envoyé avec succès.');
      } catch (error) {
        FlashUtils.sendError("L'envoi du formulaire a échoué.");
      }
    },
  },
  created() {
    this.fetchMixin.config = {
      dataFieldOne: 'form',
      fetchById: 'fetchForm',
    };
    if (this.formProp === null || this.formProp === undefined) {
      this.form = this.fetchById(this.id);
      return;
    }
    this.form = { ...this.formProp };
  },
};
</script>

<style scoped>
</style>
