<template>
  <v-container class="py-0" fluid>
    <v-row>
      <v-col>
        <v-row >
          <v-simple-table style="width: 100%" v-if="rules.length > 0">
            <thead>
            <tr>
              <th>{{ $t('common.words.answer') }}</th>
              <th>{{ $t('common.words.action') }}</th>
              <th></th>
            </tr>
            </thead>

            <tbody>
              <branching-logic
                v-for="rule in rules"
                :key="rule.id"
                :rule="rule"
                :component="component"
                @deleted="fetch"
              />
            </tbody>
          </v-simple-table>
        </v-row>

        <v-row>
          <paginator
            v-if="fetchMixin.pagination"
            :pagination="fetchMixin.pagination"
            @update="updatePagination"
          />
        </v-row>

        <v-row>
          <branching-logic-form
            v-if="possibleRuleAnswers.length > 0"
            :answers="possibleRuleAnswers" :component="component" :components="components"
            @created="fetch"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FetchMixin from '@/mixins/fech.mixin';
import BranchingLogicService from '@/services/api/branchinglogic.service';
import Paginator from '@/components/util/paginator.vue';
import BranchingLogic from '@/components/forms/components/branchinglogic/BranchingLogic.vue';
import BranchingLogicForm from '@/components/forms/components/branchinglogic/BranchingLogicForm.vue';
import ChoiceCollectionService from '@/services/api/choice-collection.service';

export default {
  name: 'branching-logics',
  components: { BranchingLogicForm, BranchingLogic, Paginator },
  mixins: [FetchMixin],
  props: {
    component: {
      type: Object,
      required: true,
    },
    components: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    rules: [],
    choiceCollection: null,
  }),
  computed: {
    possibleRuleAnswers() {
      let answers = [];

      switch (this.component.type) {
        case 'BOOLEAN_QUESTION':
          answers = [
            {
              text: this.component.trueLabel,
              answer: {
                type: 'BOOLEAN_ANSWER',
                value: true,
              },
            },
            {
              text: this.component.falseLabel,
              answer: {
                type: 'BOOLEAN_ANSWER',
                value: false,
              },
            },
          ];

          break;
        case 'MULTIPLE_CHOICE_QUESTION':
          answers = [];

          if (this.component.choices != null) {
            answers.push(...this.component.choices
              .map((choice) => ({
                text: choice,
                answer: {
                  type: 'MULTIPLE_CHOICE_ANSWER',
                  choices: [choice],
                  other: false,
                  none: false,
                },
              })));
          }

          if (this.choiceCollection != null) {
            answers.push(...this.choiceCollection.choices
              .map((choice) => ({
                text: choice,
                answer: {
                  type: 'MULTIPLE_CHOICE_ANSWER',
                  choices: [choice],
                  other: false,
                  none: false,
                },
              })));
          }

          if (this.component.otherChoice) {
            answers.push({
              text: this.$t('common.words.other'),
              answer: {
                type: 'MULTIPLE_CHOICE_ANSWER',
                choices: [],
                other: true,
                none: false,
              },
            });
          }

          if (this.component.otherChoice) {
            answers.push({
              text: this.$t('common.words.none'),
              answer: {
                type: 'MULTIPLE_CHOICE_ANSWER',
                choices: [],
                other: false,
                none: true,
              },
            });
          }
          break;
        default:
          return [];
      }
      // filter answers which already have a rule
      answers = answers.filter(({ answer }) => this.getRuleForAnswer(answer) == null);

      return answers;
    },
  },
  methods: {
    fetchChoiceCollection() {
      if (this.component.type === 'MULTIPLE_CHOICE_QUESTION'
          && this.component.choiceCollectionId != null) {
        ChoiceCollectionService.fetchChoiceCollection(this.component.choiceCollectionId)
          .then(({ data }) => {
            this.choiceCollection = data;
          }).catch(() => {
            this.choiceCollection = null;
          });
      }
    },
    fetchBranchingLogics() {
      return BranchingLogicService.fetchForComponent(this.component.id);
    },
    getRuleForAnswer(answer) {
      switch (this.component.type) {
        case 'BOOLEAN_QUESTION':
          return this.rules.find(({ answerData }) => answerData.value === answer.value) || null;

        case 'MULTIPLE_CHOICE_QUESTION':
          return this.rules.find(({ answerData }) => JSON.stringify(answerData.choices) === JSON.stringify(answer.choices)
            && answerData.other === answer.other
            && answerData.none === answer.none) || null;

        default:
          return null;
      }
    },
  },
  created() {
    this.fetchMixin.config = {
      dataField: 'rules',
      fetch: 'fetchBranchingLogics',
    };

    this.fetch();
    this.fetchChoiceCollection();
  },
};
</script>
