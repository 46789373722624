<template>
  <v-container class="py-0" fluid>
    <v-row>
      <v-col>
        <v-row>
          <v-simple-table style="width: 100%" v-if="entries.length > 0">
            <thead>
            <tr>
              <th>{{ $t('common.words.answer') }}</th>
              <th>{{ $t('common.words.action') }}</th>
              <th></th>
            </tr>
            </thead>

            <tbody>
              <entry
                v-for="entry in entries"
                :key="entry.id"
                :entry="entry"
                :component="component"
                @deleted="fetch"
              />
            </tbody>
          </v-simple-table>
        </v-row>

        <v-row>
          <paginator
            v-if="fetchMixin.pagination"
            :pagination="fetchMixin.pagination"
            @update="updatePagination"
          />
        </v-row>

        <v-row>
          <entry-form
            v-if="isIdle"
            :questionId="component.multipleChoiceQuestionId"
            :entries="entries"
            :componentId="component.id"
            :components="components"
            @created="fetch"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FetchMixin from '@/mixins/fech.mixin';
import Paginator from '@/components/util/paginator.vue';
import Entry from '@/components/forms/components/checkboxgroup/entries/Entry.vue';
import CheckBoxGroupEntryService from '@/services/api/checkbox-group-entry.service';
import EntryForm from '@/components/forms/components/checkboxgroup/entries/EntryForm.vue';

export default {
  name: 'entries',
  components: { EntryForm, Entry, Paginator },
  mixins: [FetchMixin],
  props: {
    component: {
      type: Object,
      required: true,
    },
    components: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    entries: [],
  }),
  methods: {
    fetchCheckBoxGroupEntries() {
      return CheckBoxGroupEntryService.fetchCheckBoxGroupEntries(this.component.id);
    },
  },
  created() {
    this.fetchMixin.config = {
      dataField: 'entries',
      fetch: 'fetchCheckBoxGroupEntries',
    };

    this.fetch();
  },
};
</script>
