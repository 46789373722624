var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"1080"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","x-small":"","depressed":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('pages.form.add_message'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","depressed":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-expansion-panels',{attrs:{"tile":"","accordion":"","focusable":""},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}},_vm._l((_vm.filtered),function(component,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t(`component.${component.type}.title`))+" ")]),(component.type === 'REPEATING_GROUP')?_c('v-expansion-panel-content',[_c('repeating-group-form',{attrs:{"value":{
              parentId: _vm.parentId
            }},on:{"created":_vm.componentCreated}})],1):(component.type === 'CHECK_BOX_GROUP')?_c('v-expansion-panel-content',[_c('checkbox-group-form',{attrs:{"value":{
              parentId: _vm.parentId
            }},on:{"created":_vm.componentCreated}})],1):(component.type === 'CHECK_BOX_REPEATING_GROUP')?_c('v-expansion-panel-content',[_c('checkbox-repeating-group-form',{attrs:{"value":{
              parentId: _vm.parentId
            }},on:{"created":_vm.componentCreated}})],1):_c('v-expansion-panel-content',[_c('question-form',{attrs:{"question":component,"parent-id":_vm.parentId,"parent-type":_vm.parentType,"is-creation":true},on:{"created":_vm.componentCreated}})],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }