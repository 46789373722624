<template>
  <v-dialog
    persistent
    scrollable
    v-model="open"
    max-width="1080"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        x-small
        depressed
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>
          {{ $t('pages.form.add_message') }}
        </v-toolbar-title>

        <v-spacer/>

        <v-btn
          icon
          depressed
          @click="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-expansion-panels tile accordion focusable v-model="selection">
        <v-expansion-panel
          v-for="(component, i) in filtered" :key="i"
        >
          <v-expansion-panel-header>
            {{ $t(`component.${component.type}.title`) }}
          </v-expansion-panel-header>

          <v-expansion-panel-content v-if="component.type === 'REPEATING_GROUP'">
            <repeating-group-form
              :value="{
                parentId
              }"
              @created="componentCreated"
            />
          </v-expansion-panel-content>

          <v-expansion-panel-content  v-else-if="component.type === 'CHECK_BOX_GROUP'">
            <checkbox-group-form
              :value="{
                parentId
              }"
              @created="componentCreated"
            />
          </v-expansion-panel-content>

          <v-expansion-panel-content  v-else-if="component.type === 'CHECK_BOX_REPEATING_GROUP'">
            <checkbox-repeating-group-form
              :value="{
                parentId
              }"
              @created="componentCreated"
            />
          </v-expansion-panel-content>

          <v-expansion-panel-content v-else>
            <question-form
              :question="component"
              :parent-id="parentId"
              :parent-type="parentType"
              :is-creation="true"
              @created="componentCreated"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-dialog>
</template>

<script>
import QuestionForm from '@/components/forms/components/question/QuestionForm.vue';
import RepeatingGroupForm from '@/components/forms/components/repeatinggroup/RepeatingGroupForm.vue';
import CheckboxGroupForm from '@/components/forms/components/checkboxgroup/CheckboxGroupForm.vue';
import CheckboxRepeatingGroupForm from '@/components/forms/components/checkboxrepeatinggroup/CheckboxRepeatingGroupForm.vue';

export default {
  name: 'new-component-modal',
  components: {
    CheckboxRepeatingGroupForm, CheckboxGroupForm, RepeatingGroupForm, QuestionForm,
  },
  props: {
    parentId: { type: Number, required: true },
    parentType: { type: String, required: true },
    withGroup: { type: Boolean, default: true },
  },
  data: () => ({
    selection: null,
    open: false,
    components: [
      {
        type: 'TEXT_QUESTION',
        label: '',
        variable: '',
        notKnownChoice: true,
        skipChoice: true,
      },
      {
        type: 'NUMBER_QUESTION',
        label: '',
        variable: '',
        min: null,
        max: null,
        display: 'INPUT',
        notKnownChoice: true,
        skipChoice: true,
      },
      {
        type: 'BOOLEAN_QUESTION',
        label: '',
        variable: '',
        trueLabel: '',
        falseLabel: '',
        notKnownChoice: true,
        skipChoice: true,
      },
      {
        type: 'DATE_QUESTION',
        label: '',
        variable: '',
        min: null,
        max: null,
        updateTerm: false,
        updateDelivery: false,
        notKnownChoice: true,
        skipChoice: true,
      },
      {
        type: 'MULTIPLE_CHOICE_QUESTION',
        label: '',
        variable: '',
        multipleSelection: true,
        otherChoice: false,
        noneChoice: true,
        choices: [],
        choiceCollectionId: null,
        notKnownChoice: true,
        skipChoice: true,
      },
      {
        type: 'CHECK_BOX_GROUP',
        multipleChoiceQuestionId: null,
      },
      {
        type: 'REPEATING_GROUP',
        numberQuestionId: null,
      },
      {
        type: 'CHECK_BOX_REPEATING_GROUP',
        multipleChoiceQuestionId: null,
      },
    ],
  }),
  computed: {
    filtered() {
      if (this.withGroup) {
        return this.components;
      }
      return this.components.filter((component) => !component.type.includes('GROUP'));
    },
  },
  methods: {
    close() {
      this.open = false;
      this.$emit('cancelled');
    },
    componentCreated(data) {
      this.$emit('component-created', data);
      this.close();
    },
  },
};
</script>

<style scoped>
.v-card {
  overflow: hidden;
}

.v-expansion-panels {
  overflow: auto;
}
</style>
