import CreatorService from '@/services/creator.service';
import AxiosApiService from '@/services/axios/axios.api.service';

const CheckBoxGroupEntryService = {
  ...CreatorService.createCrud(
    AxiosApiService, 'checkBoxGroupEntry', 'checkBoxGroupEntries', '/checkBoxGroupEntries',
    ['fetchCheckBoxGroupEntries', 'fetchCheckBoxGroupEntry', 'updateCheckBoxGroupEntry'],
  ),
  fetchCheckBoxGroupEntries(checkBoxGroupId) {
    return AxiosApiService.get('/checkBoxGroupEntries', true, { checkBoxGroupId });
  },
};

export default CheckBoxGroupEntryService;
