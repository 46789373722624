<template>
  <v-col class="pt-0">
    <v-row>
      <v-text-field
        v-bind:label="$t('pages.form.question.true_wording_label')"
        name="trueLabel"
        type="text"
        v-model="question.trueLabel"
        :rules="[(v) => v !== '' || $t('errors.empty', {the: $t('common.words.The_male'), name: $t('common.labels.mwording')})]"
      />
    </v-row>

    <v-row>
      <v-text-field
        v-bind:label="$t('pages.form.question.false_wording_label')"
        name="falseLabel"
        type="text"
        v-model="question.falseLabel"
        :rules="[(v) => v !== '' || $t('errors.empty', {the: $t('common.words.The_male'), name: $t('common.labels.mwording')})]"
      />
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'boolean-question',
  props: {
    question: { type: Object, required: true },
  },
};
</script>
