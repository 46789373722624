<template>
  <v-dialog max-width="1080" scrollable v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-on="on"
        v-bind="attrs"
        v-if="component.type === 'CHECK_BOX_GROUP' && parentType === 'FORM'"
      >
        <v-icon>mdi-source-branch</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-container class="pr-0" fluid>
          <v-row justify="space-between" align="center">
            <v-toolbar-title class="text-h5">
              {{ $t('component.CHECK_BOX_GROUP.branching_logic') }} #{{component.id}}
            </v-toolbar-title>

            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-toolbar>

      <v-card-text>
        <entries :component="component" :components="component.components"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Entries from './Entries.vue';

export default {
  name: 'entries-modal',
  components: { Entries },
  props: {
    parentType: { type: String, required: true },
    component: { type: Object, required: true },
    components: { type: Array, required: true },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    close() {
      this.dialog = false;
    },
  },
};
</script>
