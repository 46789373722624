<template>
  <v-col>
    <v-row>
      <v-checkbox name="minOn" :input-value="minOn" @click="updateMinOn"/>
      <date-field
        v-bind:label="$t('pages.form.question.min_date')"
        v-model="question.min"
        @input="dateInput('min', $event)"
        :disabled="!minOn"
        :rules="[
          (v) => !minOn || isValid(v) || $t('errors.invalid_date'),
          (v) => !minOn || !maxOn || isBefore(v, this.question.max)
            || $t('errors.date_min_max'),
        ]"
      />
    </v-row>

    <v-row>
      <v-checkbox name="maxOn" :input-value="maxOn" @change="updateMaxOn"/>
      <date-field
        v-bind:label="$t('pages.form.question.max_date')"
        v-model="question.max"
        @input="dateInput('max', $event)"
        :disabled="!maxOn"
        :rules="[
          (v) => !maxOn || isValid(v) || $t('errors.invalid_date'),
          (v) => !minOn || !maxOn || isBefore(this.question.min, v)
            || $t('errors.date_max_min'),
        ]"
      />
    </v-row>

    <v-row>
      <v-checkbox
        v-bind:label="$t('pages.form.question.update_date', { event: $t('common.messages.term') })"
        name="updateTerm" v-model="question.updateTerm"
      />
    </v-row>

    <v-row>
      <v-checkbox
        v-bind:label="$t('pages.form.question.update_date', { event: $t('common.messages.delivery') })"
        name="updateTerm" v-model="question.updateDelivery"
      />
    </v-row>
  </v-col>
</template>

<script>
import DateField from '@/components/util/DateField.vue';

export default {
  name: 'date-question',
  components: { DateField },
  props: {
    question: { type: Object, required: true },
  },
  data: () => ({
    minDialog: false,
    maxDialog: false,
    min: null,
    max: null,
  }),
  computed: {
    minOn() {
      return this.question.min !== null;
    },
    maxOn() {
      return this.question.max !== null;
    },
  },
  methods: {
    updateMinOn() {
      this.$set(this.question, this.$t('common.words.mmin'), this.minOn ? null : this.min);
    },
    updateMaxOn() {
      this.$set(this.question, this.$t('common.words.mmax'), this.maxOn ? null : this.max);
    },
    dateInput(which, value) {
      this.$set(this, which, value);
      this.$emit('should-validate');
    },
    isValid(v) {
      return /^\d\d\.\d\d\.\d\d\d\d$/.test(v);
    },
    isBefore(a, b) {
      if (!this.isValid(a) || !this.isValid(b)) return true;

      const dateA = Date.parse(this.swapDate(a, '.', '-'));
      const dateB = Date.parse(this.swapDate(b, '.', '-'));

      return dateA < dateB;
    },
    swapDate(date, separatorA, separatorB) {
      return date.split(separatorA).reverse().join(separatorB);
    },
  },
  created() {
    this.min = this.question.min || '';
    this.max = this.question.max || '';
  },
};
</script>
