import CreatorService from '@/services/creator.service';
import AxiosApiService from '@/services/axios/axios.api.service';

const BranchingLogicService = {
  ...CreatorService.createCrud(
    AxiosApiService, 'branchingLogic', 'branchingLogics', '/branchingLogics',
    ['fetchBranchingLogics', 'fetchBranchingLogic'],
  ),
  fetchForComponent(componentId) {
    return AxiosApiService.get(`/branchingLogics/forComponent/${componentId}`);
  },
};

export default BranchingLogicService;
