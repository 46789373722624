<template>
  <v-select
    v-bind:label="$t('pages.choice_collection_info.collection_choice')"
    :loading="fetchMixin.state === fetchMixin.STATES.LOADING"
    :items="collections"
    item-value="id"
    item-text="name"
    :value="value"
    @change="$emit('input', $event)"
    :rules="[(v) => v !== null || $t('pages.choice_collection_info.choice_message')]"
  >
    <template v-slot:prepend-item>
      <div class="selectTop"></div>
    </template>

    <template v-slot:append-item>
      <v-list-item>
        <paginator
          v-if="fetchMixin.pagination"
          :pagination="fetchMixin.pagination"
          @update="updatePagination"
        />
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import Paginator from '@/components/util/paginator.vue';
import FetchMixin from '@/mixins/fech.mixin';
import ChoiceCollectionService from '@/services/api/choice-collection.service';

export default {
  name: 'choice-collection-select',
  mixins: [FetchMixin],
  components: { Paginator },
  props: {
    value: { default: null },
  },
  data: () => ({
    collections: [],
  }),
  methods: {
    fetchChoiceCollections() {
      const { page, size } = this.pagination || {
        page: 1,
        size: 20,
      };
      return ChoiceCollectionService.fetchChoiceCollections({
        page,
        size,
      });
    },
  },
  created() {
    this.fetchMixin.config = {
      fetch: 'fetchChoiceCollections',
      dataField: 'collections',
    };
    this.fetch();
  },
};
</script>
