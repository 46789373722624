<template>
  <v-col class="pt-0">
    <v-row>
      <v-checkbox name="minOn" :input-value="minOn" @click="updateMinOn"/>
      <v-text-field
        v-bind:label="$t('common.labels.minimum')" name="min" type="text" v-model="question.min" :disabled="!minOn"
        @input="min = $event" @change="min = $event"
        :rules="[
          (v) => !minOn && v == null || isValid(v) || $t('errors.invalid_number'),
          (v) => !minOn || !maxOn || Number(v) <= Number(question.max)
            || $t('errors.min_max'),
        ]"
      />
    </v-row>

    <v-row>
      <v-checkbox name="maxOn" :input-value="maxOn" @change="updateMaxOn"/>
      <v-text-field
        v-bind:label="$t('common.labels.maximum')" name="max" type="text" v-model="question.max" :disabled="!maxOn"
        @input="max = $event" @change="max = $event"
        :rules="[
          (v) => !maxOn && v == null || isValid(v) || $t('errors.invalid_number'),
          (v) => !minOn || !maxOn || Number(v) >= Number(question.min)
            || $t('errors.max_min'),
        ]"
      />
    </v-row>

    <v-row>
      <v-text-field
        v-bind:label="$t('common.labels.answer_sample')" name="defaultValue" type="number" v-model="question.defaultValue"
        :rules="[
          (v) => v == null || v === '' || isValid(v) || $t('errors.invalid_number'),
          (v) => v == null || v === ''
            || ((!minOn || Number(v) >= Number(question.min)) && (!maxOn || Number(v) <= Number(question.max)))
            || $t('errors.min_example_max'),
        ]"
      />
    </v-row>

    <v-row>
      <v-select
        v-bind:label="$t('pages.form.display_type')"
        :items="displays"
        v-model="question.display"
      >
        <template v-slot:item="{ item }">
          {{ $t(`component.NUMBER_QUESTION.display.${item}`) }}
        </template>
        <template v-slot:selection="{ item }">
          {{ $t(`component.NUMBER_QUESTION.display.${item}`) }}
        </template>
      </v-select>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'number-question',
  props: {
    question: { type: Object, required: true },
  },
  data: () => ({
    min: 0,
    max: 0,
    displays: [
      'INPUT', 'SLIDER', 'RATING', 'DROPDOWN_ASC', 'DROPDOWN_DESC',
    ],
  }),
  computed: {
    minOn() {
      return this.question.min !== null;
    },
    maxOn() {
      return this.question.max !== null;
    },
  },
  methods: {
    updateMinOn() {
      this.$set(this.question, this.$t('common.words.mmin'), this.minOn ? null : this.min);
    },
    updateMaxOn() {
      this.$set(this.question, this.$t('common.words.mmax'), this.maxOn ? null : this.max);
    },
    isValid(v) {
      return /^[+-]?\d+$/.test(v);
      // return /^[+-]?(\d+|\d+\.\d*|\d*\.\d+)$/.test(v);
    },
  },
  created() {
    this.min = this.question.min || 0;
    this.max = this.question.max || 0;
  },
};
</script>
