var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"pt-0"},[_c('v-row',[_c('v-checkbox',{attrs:{"name":"minOn","input-value":_vm.minOn},on:{"click":_vm.updateMinOn}}),_c('v-text-field',{attrs:{"label":_vm.$t('common.labels.minimum'),"name":"min","type":"text","disabled":!_vm.minOn,"rules":[
        (v) => !_vm.minOn && v == null || _vm.isValid(v) || _vm.$t('errors.invalid_number'),
        (v) => !_vm.minOn || !_vm.maxOn || Number(v) <= Number(_vm.question.max)
          || _vm.$t('errors.min_max'),
      ]},on:{"input":function($event){_vm.min = $event},"change":function($event){_vm.min = $event}},model:{value:(_vm.question.min),callback:function ($$v) {_vm.$set(_vm.question, "min", $$v)},expression:"question.min"}})],1),_c('v-row',[_c('v-checkbox',{attrs:{"name":"maxOn","input-value":_vm.maxOn},on:{"change":_vm.updateMaxOn}}),_c('v-text-field',{attrs:{"label":_vm.$t('common.labels.maximum'),"name":"max","type":"text","disabled":!_vm.maxOn,"rules":[
        (v) => !_vm.maxOn && v == null || _vm.isValid(v) || _vm.$t('errors.invalid_number'),
        (v) => !_vm.minOn || !_vm.maxOn || Number(v) >= Number(_vm.question.min)
          || _vm.$t('errors.max_min'),
      ]},on:{"input":function($event){_vm.max = $event},"change":function($event){_vm.max = $event}},model:{value:(_vm.question.max),callback:function ($$v) {_vm.$set(_vm.question, "max", $$v)},expression:"question.max"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"label":_vm.$t('common.labels.answer_sample'),"name":"defaultValue","type":"number","rules":[
        (v) => v == null || v === '' || _vm.isValid(v) || _vm.$t('errors.invalid_number'),
        (v) => v == null || v === ''
          || ((!_vm.minOn || Number(v) >= Number(_vm.question.min)) && (!_vm.maxOn || Number(v) <= Number(_vm.question.max)))
          || _vm.$t('errors.min_example_max'),
      ]},model:{value:(_vm.question.defaultValue),callback:function ($$v) {_vm.$set(_vm.question, "defaultValue", $$v)},expression:"question.defaultValue"}})],1),_c('v-row',[_c('v-select',{attrs:{"label":_vm.$t('pages.form.display_type'),"items":_vm.displays},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(`component.NUMBER_QUESTION.display.${item}`))+" ")]}},{key:"selection",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(`component.NUMBER_QUESTION.display.${item}`))+" ")]}}]),model:{value:(_vm.question.display),callback:function ($$v) {_vm.$set(_vm.question, "display", $$v)},expression:"question.display"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }