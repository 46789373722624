<template>
  <v-dialog max-width="1080" scrollable v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-on="on"
        v-bind="attrs"
        v-if="(component.type === 'BOOLEAN_QUESTION'
          || component.type === 'MULTIPLE_CHOICE_QUESTION')
          && parentType === 'FORM'"
      >
        <v-icon>mdi-source-branch</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-container class="pr-0" fluid>
          <v-row justify="space-between" align="center">
            <v-toolbar-title class="text-h5">
              {{ $t('component.branching_logic') }} #{{component.id}}
            </v-toolbar-title>

            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-toolbar>

      <v-card-text class="mt-4 text-body-2">
        {{ $t('common.messages.question_wording') }}: {{component.label}}
      </v-card-text>

      <v-card-text>
        <branching-logics :component="component" :components="components"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BranchingLogics from './BranchingLogics.vue';

export default {
  name: 'branching-logics-modal',
  components: { BranchingLogics },
  props: {
    parentType: { type: String, required: true },
    component: { type: Object, required: true },
    components: { type: Array, required: true },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    close() {
      this.dialog = false;
    },
  },
};
</script>
