<template>
  <v-form v-if="question != null" ref="form" style="width: 100%" v-model="formValid">
    <v-col>
      <v-row class="text-h6">
        {{ $t('common.action.add') }} {{ $t('common.messages.a_new_fem') }} {{ $t('common.messages.branching_logic') }}
      </v-row>

      <v-row v-if="answers">
        <v-select
          v-bind:label="$t('common.labels.if_answer')"
          :items="answers"
          v-model="model.choice"
          :rules="[(v) => v != null || $t('errors.choose_in_list', {name: $t('common.words.manswer')})]"
        />
      </v-row>

      <v-row>
        <v-select
          v-bind:label="$t('common.labels.show_question')"
          :items="actions"
          v-model="model.questionId"
          :rules="[(v) => v != null || $t('errors.choose_in_list', {name: $t('common.words.maction')})]"
        />
      </v-row>

      <v-row justify="space-between" align="center">
        <v-spacer/>
        <v-btn
          depressed color="primary" @click="createRule"
          :disabled="!formValid || createMixin.loading" :loading="isLoading"
        >
          {{ $t('common.action.add') }}
        </v-btn>
      </v-row>
    </v-col>
  </v-form>
</template>

<script>
import CreateMixin from '@/mixins/create.mixin';
import FetchMixin from '@/mixins/fech.mixin';
import CheckBoxGroupEntryService from '@/services/api/checkbox-group-entry.service';
import ComponentService from '@/services/api/component.service';

export default {
  name: 'entry-form',
  mixins: [CreateMixin, FetchMixin],
  props: {
    questionId: { required: true },
    componentId: { required: true },
    components: { type: Array, default: null },
    entries: { type: Array, default: null },
  },
  data: () => ({
    question: null,
    formValid: false,
    model: {
      choice: null,
      questionId: null,
    },
  }),
  computed: {
    answers() {
      return this.question.choices.filter(
        (choice) => this.entries.find((entry) => entry.choice === choice) == null,
      );
    },
    actions() {
      return this.components.map((component) => ({
        text: `#${component.id}: ${component.label}`,
        value: component.id,
      }));
    },
  },
  methods: {
    isTypeExcluded(type) {
      return this.excludeTypes.includes(type);
    },
    createRule() {
      this.$refs.form.validate();
      if (!this.formValid) return;

      this.create();
    },
    createBranchingLogic() {
      const entry = {
        ...this.model,
        checkBoxGroupId: this.componentId,
      };

      return CheckBoxGroupEntryService.createCheckBoxGroupEntry(entry);
    },
    fetchQuestion(id) {
      return ComponentService.fetchComponent(id);
    },
  },
  created() {
    this.createMixin.config = {
      create: 'createBranchingLogic',
    };

    this.fetchMixin.config = {
      fetchById: 'fetchQuestion',
      dataFieldOne: 'question',
    };

    this.fetchById(this.questionId);
  },
};
</script>
