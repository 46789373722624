<template>
  <div>
    <repeating-group-form
      :value="repeatingGroup"
    />
    <component-list
      :parentId="repeatingGroup.id"
      parent-type="REPEATING_GROUP"
      :data="repeatingGroup.components"
      :withGroup="false"
    />
  </div>
</template>

<script>
import RepeatingGroupForm from '@/components/forms/components/repeatinggroup/RepeatingGroupForm.vue';

export default {
  name: 'repeating-group',
  components: {
    RepeatingGroupForm,
    ComponentList: () => import('@/components/forms/components/ComponentList.vue'),
  },
  props: {
    repeatingGroup: { type: Object, required: true },
  },
  data: () => ({
  }),
  methods: {

  },
};
</script>
