<template>
  <v-card tile outlined>
<!--    <v-card-title class="text-h6">-->
<!--      {{ $t(`component.${component.type}.title`) }}-->
<!--    </v-card-title>-->
    <v-toolbar flat tile>
      <v-toolbar-title>
        {{ $t(`component.${component.type}.title`)  }} #{{ component.id }}
      </v-toolbar-title>

      <v-spacer/>

      <slot name="actions"/>

      <branching-logics-modal
        :parent-type="parentType" :component="component" :components="components"
      />

      <entries-modal
        :parent-type="parentType" :component="component" :components="components"
      />

      <confirm-button width="400" @click="remove(component.id)">
        <template v-slot:button="{ on, attrs }">
          <v-btn
            icon
            depressed
            color="error"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template v-slot:title style="word-wrap: break-word">
          {{ $t('pages.form_info.component.delete_validation_1') }}<br/>
          {{ $t('pages.form_info.component.delete_validation_2') }}
        </template>

        <template v-slot:message>
          {{ $t('pages.form_info.component.delete_validation_message') }}
        </template>
      </confirm-button>
    </v-toolbar>
    <v-divider/>

    <v-card-text>
      <RepeatingGroup
        :repeating-group="component"
        @updated="$emit('component-modified', $event)"
        v-if="component.type === 'REPEATING_GROUP'"
      />
      <CheckboxGroup
        :checkbox-group="component"
        @updated="$emit('component-modified', $event)"
        v-else-if="component.type === 'CHECK_BOX_GROUP'"
      />
      <CheckboxRepeatingGroup
        :checkbox-repeating-group="component"
        @updated="$emit('component-modified', $event)"
        v-else-if="component.type === 'CHECK_BOX_REPEATING_GROUP'"
      />
      <Question
        :question="component"
        @updated="$emit('component-modified', $event)"
        v-else
      />
    </v-card-text>
  </v-card>
</template>

<script>
import ConfirmButton from '@/components/util/ConfirmButton.vue';
import DeleteMixin from '@/mixins/delete.mixin';
import ComponentService from '@/services/api/component.service';
import CheckboxGroup from '@/components/forms/components/checkboxgroup/CheckboxGroup.vue';
import EntriesModal from '@/components/forms/components/checkboxgroup/entries/EntriesModal.vue';
import CheckboxRepeatingGroup
from '@/components/forms/components/checkboxrepeatinggroup/CheckboxRepeatingGroup.vue';
import Question from './question/QuestionForm.vue';
import RepeatingGroup from './repeatinggroup/RepeatingGroup.vue';
import BranchingLogicsModal from './branchinglogic/BranchingLogicsModal.vue';

export default {
  name: 'component-card',
  mixins: [DeleteMixin],
  components: {
    CheckboxRepeatingGroup,
    EntriesModal,
    CheckboxGroup,
    BranchingLogicsModal,
    ConfirmButton,
    Question,
    RepeatingGroup,
  },
  props: {
    parentType: { type: String, required: true },
    component: { type: Object, required: true },
    components: { type: Array, required: true },
  },
  methods: {
    deleteComponent(id) {
      return ComponentService.deleteComponent(id);
    },
  },
  created() {
    this.deleteMixin.config = {
      delete: 'deleteComponent',
    };
  },
};
</script>
