<template>
  <v-form ref="form" v-model="isValid" style="width: auto;">
    <v-container fluid>
      <v-row justify="space-between">
        <group-candidate-selection
          :label="$t('component.MULTIPLE_CHOICE_QUESTION.title')"
          :explanation="$t('component.CHECK_BOX_GROUP.candidate.explanation')"
          :rules="[(v) => v !== null || $t('component.CHECK_BOX_GROUP.error')]"
          :question-id="model.multipleChoiceQuestionId"
          :filter="{ type: 'MULTIPLE_CHOICE_QUESTION', multipleSelection:true, parentId: value.parentId }"
          @selected="candidateSelected"
        />
      </v-row>

      <v-row>
        <v-btn
          v-if="!isInCreationMode"
          depressed
          :disabled="!hasChanged"
          @click="resetForm"
        >
          {{ $t('common.action.reinitialize') }}
        </v-btn>

        <v-spacer/>

        <v-btn
          v-if="isInCreationMode"
          depressed
          color="primary"
          :disabled="!isValid"
          :loading="isLoading"
          @click="create"
        >
          {{ $t('common.action.create') }}
        </v-btn>
        <v-btn v-else
               depressed
               color="primary"
               :disabled="!isValid || !hasChanged"
               :loading="isLoading"
               @click="update"
        >
          {{ $t('common.action.modify') }}
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import GroupCandidateSelection from '@/components/forms/components/candidate/GroupCandidateSelection.vue';
import CreateMixin from '@/mixins/create.mixin';
import UpdateMixin from '@/mixins/update.mixin';
import ComponentService from '@/services/api/component.service';

export default {
  name: 'checkbox-group-form',
  mixins: [CreateMixin, UpdateMixin],
  components: { GroupCandidateSelection },
  props: {
    // parentId
    value: { require: true, default: null },
  },
  data: () => ({
    model: {
      type: 'CHECK_BOX_GROUP',
    },
    isValid: false,
  }),
  methods: {
    submit() {
      this.$refs.form.validate();
      if (!this.isValid) return;
      if (this.isInCreationMode) {
        this.create();
      } else {
        this.update();
      }
    },
    resetForm() {
      this.model = { ...this.value };
    },
    createCheckboxGroup() {
      return ComponentService.createComponent(this.model);
    },
    updateCheckboxGroup() {
      const { id, ...rest } = this.model;
      return ComponentService.updateComponent(id, rest);
    },
    candidateSelected(candidate) {
      this.model.multipleChoiceQuestionId = candidate.id;
    },
  },
  computed: {
    isInCreationMode() {
      return this.model.id === null || this.model.id === undefined;
    },
    hasChanged() {
      return JSON.stringify(this.model) !== JSON.stringify(this.value);
    },
  },
  created() {
    this.model = { ...this.value, ...this.model };

    this.createMixin.config = {
      create: 'createCheckboxGroup',
    };
    this.updateMixin.config = {
      update: 'updateCheckboxGroup',
    };
  },
};
</script>
