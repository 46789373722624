<template>
        <tr>
          <td>{{answerLabel}}</td>
          <td>{{actionLabel}}</td>
          <td class="text-right">
            <v-btn
              icon depressed color="error"
              :disabled="state"
              @click="remove(rule.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
</template>

<script>
import BranchingLogicService from '@/services/api/branchinglogic.service';
import DeleteMixin from '@/mixins/delete.mixin';

export default {
  name: 'branching-logic',
  mixins: [DeleteMixin],
  props: {
    rule: { type: Object, required: true },
    component: { type: Object, required: true },
  },
  data: () => ({
    state: false,
  }),
  computed: {
    answerLabel() {
      const { answerData } = this.rule;
      switch (this.component.type) {
        case 'BOOLEAN_QUESTION':
          return answerData.value ? this.component.trueLabel : this.component.falseLabel;
        case 'MULTIPLE_CHOICE_QUESTION':
          return answerData.choices[0];
        default:
          // Should never happen
          return '';
      }
    },
    actionLabel() {
      switch (this.rule.type) {
        case 'NEXT_COMPONENT':
          return this.$t('common.messages.next_component', { component: this.rule.nextComponentId });
        case 'DELAY_FORM':
          return this.$t('common.messages.delay_form');
        case 'END_FORM':
          return this.$t('common.messages.end_form');
        case 'END_RESEARCH':
          return this.$t('common.messages.end_research', { formId: this.rule.formId });
        default:
          // Should never happen
          return '';
      }
    },
  },
  methods: {
    deleteBranchingLogic(id) {
      return BranchingLogicService.deleteBranchingLogic(id);
    },
  },
  created() {
    this.deleteMixin.config = {
      delete: 'deleteBranchingLogic',
      callback: 'deleteCallback',
    };
  },
};
</script>

<style scoped>
.v-row {
  padding-bottom: 12px;
}
</style>
