<template>
        <tr>
          <td>{{answerLabel}}</td>
          <td>{{actionLabel}}</td>
          <td class="text-right">
            <v-btn
              icon depressed color="error"
              :disabled="state"
              @click="remove(entry.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
</template>

<script>
import DeleteMixin from '@/mixins/delete.mixin';
import CheckBoxGroupEntryService from '@/services/api/checkbox-group-entry.service';

export default {
  name: 'entry',
  mixins: [DeleteMixin],
  props: {
    entry: { type: Object, required: true },
    component: { type: Object, required: true },
  },
  data: () => ({
    state: false,
  }),
  computed: {
    answerLabel() {
      return this.entry.choice;
    },
    actionLabel() {
      return `Afficher la question #${this.entry.questionId}`;
    },
  },
  methods: {
    deleteEntry(id) {
      return CheckBoxGroupEntryService.deleteCheckBoxGroupEntry(id);
    },
    deleteCallback(id) {
      this.$emit('deleted', id);
    },
  },
  created() {
    this.deleteMixin.config = {
      delete: 'deleteEntry',
      callback: 'deleteCallback',
    };
  },
};
</script>

<style scoped>
.v-row {
  padding-bottom: 12px;
}
</style>
