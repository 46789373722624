<template>
  <v-card outlined flat>
    <v-card-title v-if="isInCreationMode">
      {{ $t('common.action.create') }}
      {{ $t('common.messages.a_new_masc') }}
      {{ $t('pages.form_info.name') }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="validForm">
        <v-text-field v-bind:label="$t('common.labels.name')" name="name" type="text" v-model="model.label"
          @keypress.enter.prevent="submit"
          :rules="[(v) => v !== '' || $t('errors.empty', {the: $t('common.words.The_male'), name: $t('common.labels.mname')})]"/>
        <v-text-field v-bind:label="$t('common.labels.description')" name="description" type="text" v-model="model.description"
                      @keypress.enter.prevent="submit"/>
        <v-text-field v-bind:label="$t('common.labels.article')" name="article" type="text" v-model="model.article"
                      @keypress.enter.prevent="submit"/>
      </v-form>
    </v-card-text>

    <v-card-actions>
        <v-spacer/>
        <v-btn v-if="isInCreationMode" depressed color="primary" @click="submit"
          :loading="createMixin.state === createMixin.STATES.CREATING"
          :disabled="!validForm">
          <span>{{ $t('common.action.create') }}</span>
        </v-btn>
      <v-btn v-else depressed color="primary" @click="submit"
             :loading="updateMixin.state === updateMixin.STATES.CREATING"
             :disabled="!validForm">
        <span>{{ $t('common.action.modify') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FormService from '@/services/api/form.service';
import CreateMixin from '@/mixins/create.mixin';
import UpdateMixin from '@/mixins/update.mixin';

export default {
  name: 'form-creation-form',
  mixins: [CreateMixin, UpdateMixin],
  props: ['value'],
  data: () => ({
    model: {},
    validForm: false,
  }),
  watch: {
    value() {
      this.model = { ...this.value };
    },
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (!this.validForm) return;
      if (this.isInCreationMode) {
        this.create();
      } else {
        this.update();
      }
    },
    createForm() {
      return FormService.createForm(this.model);
    },
    updateForm() {
      const { id, ...rest } = this.model;
      return FormService.updateForm(id, rest);
    },
  },
  computed: {
    isInCreationMode() {
      return this.model.id === null || this.model.id === undefined;
    },
  },
  created() {
    this.createMixin.config = {
      create: 'createForm',
    };
    this.updateMixin.config = {
      update: 'updateForm',
    };
    this.model = { ...this.value };
  },

};
</script>
