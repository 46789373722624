<template>
  <div>
    <checkbox-group-form
      :value="checkboxGroup"
    />
    <component-list
      :parentId="checkboxGroup.id"
      parent-type="CHECK_BOX_GROUP"
      :data="checkboxGroup.components"
      :withGroup="false"
    />
  </div>
</template>

<script>
import CheckboxGroupForm from '@/components/forms/components/checkboxgroup/CheckboxGroupForm.vue';

export default {
  name: 'checkbox-group',
  components: {
    CheckboxGroupForm,
    ComponentList: () => import('@/components/forms/components/ComponentList.vue'),
  },
  props: {
    checkboxGroup: { type: Object, required: true },
  },
  data: () => ({
  }),
  methods: {

  },
};
</script>
