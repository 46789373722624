import CreatorService from '@/services/creator.service';
import AxiosApiService from '@/services/axios/axios.api.service';

const ComponentService = {
  ...CreatorService.createCrud(AxiosApiService, 'component', 'components', '/components'),
  /**
   * Swap two component in form
   * @param {Number} formId The id of the form.
   * @param {Number} lhsId The id of the component.
   * @param {Number} rhsId The id of the component.
   */
  async swapComponents(parentId, lhsId, rhsId) {
    return AxiosApiService.post('/components/swap', { parentId, lhsId, rhsId });
  },

  async queryComponent(body, pagination) {
    return AxiosApiService.post('/components/query', body, true, pagination);
  },
};

export default ComponentService;
