<template>
  <v-form ref="form" v-model="formValid">
    <v-container>
      <v-row>
        <v-text-field
          v-bind:label="$t('common.labels.variable')" name="variable" type="text" v-model="model.variable"
          :rules="[(v) => v !== '' || $t('errors.empty', {the: $t('common.words.The_female'), name: $t('common.labels.mvariable')})]"
        />
      </v-row>
      <v-row>
        <v-text-field
          v-bind:label="$t('common.labels.wording')" name="label" type="text" v-model="model.label"
          :rules="[(v) => v !== '' || $t('errors.empty', {the: $t('common.words.The_male'), name: $t('common.labels.mwording')})]"
        />
      </v-row>
      <v-row>
        <v-text-field
          v-bind:label="$t('common.labels.description')" name="description" type="text" v-model="model.description"
        />
      </v-row>
      <v-row>
<!--        <text-question
          :question="model"
          v-if="question.type === 'TEXT_QUESTION'"
        />-->
        <number-question
          :question="model"
          v-if="question.type === 'NUMBER_QUESTION'"
        />
        <boolean-question
          :question="model"
          v-else-if="question.type === 'BOOLEAN_QUESTION'"
        />
        <date-question
          :question="model"
          @should-validate="$refs.form.validate()"
          v-else-if="question.type === 'DATE_QUESTION'"
        />
        <multiple-choice-question
          :question="model"
          :show-choices="!isCreation"
          v-else-if="question.type === 'MULTIPLE_CHOICE_QUESTION'"
        />
      </v-row>

      <v-row>
        <v-checkbox
          v-bind:label="$t('common.messages.not_known')" v-model="model.notKnownChoice"
        />
      </v-row>

      <v-row>
        <v-checkbox
          v-bind:label="$t('common.messages.no_answer')" v-model="model.skipChoice"
        />
      </v-row>

      <v-row justify="space-between">
        <v-btn
          depressed
          @click.prevent="resetForm"
          :disabled="isIdle || !hasChanged"
        >
          {{ $t('common.action.reinitialize') }}
        </v-btn>

        <v-btn
          depressed color="primary"
          @click.prevent="submit()"
          :disabled="!formValid || isIdle || !hasChanged"
          :loading="isLoading"
        >
          {{ isCreation ? $t('common.action.create') : $t('common.action.modify') }}
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import CreateMixin from '@/mixins/create.mixin';
import UpdateMixin from '@/mixins/update.mixin';
import ComponentService from '@/services/api/component.service';
import NumberQuestion from './NumberQuestion.vue';
import BooleanQuestion from './BooleanQuestion.vue';
import DateQuestion from './DateQuestion.vue';
import MultipleChoiceQuestion from './MultipleChoiceQuestion.vue';

export default {
  name: 'question-form',
  props: {
    question: { type: Object, required: true },
    isCreation: { type: Boolean, default: false },
    parentId: { type: Number },
    parentType: { type: String },
  },
  mixins: [CreateMixin, UpdateMixin],
  components: {
    MultipleChoiceQuestion,
    DateQuestion,
    NumberQuestion,
    BooleanQuestion,
  },
  data: () => ({
    formValid: false,
    model: null,
  }),
  computed: {
    hasChanged() {
      return JSON.stringify(this.question) !== JSON.stringify(this.model);
    },
    isIdle() {
      return this.createMixin.isIdle && this.updateMixin.isIdle;
    },
    isLoading() {
      return this.createMixin.isLoading && this.updateMixin.isLoading;
    },
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (!this.formValid) return;
      if (this.isCreation) {
        this.model.parentId = this.parentId;
        this.create();
        this.resetForm();
      } else {
        this.update();
      }
      this.$refs.form.resetValidation();
    },
    createComponent() {
      return ComponentService.createComponent(this.model);
    },
    updateComponent() {
      const { id, ...rest } = this.model;
      return ComponentService.updateComponent(id, rest);
    },
    resetForm() {
      this.model = { ...this.question };
    },
  },
  created() {
    this.resetForm();
    this.createMixin.config = {
      create: 'createComponent',
    };
    this.updateMixin.config = {
      update: 'updateComponent',
    };
  },
  watch: {
    question() {
      this.resetForm();
    },
  },
};
</script>
