<template>
  <v-card
    flat tile :loading="fetchMixin.state === fetchMixin.STATES.LOADING"
  >
    <v-card-title class="text-h5">
      <v-row no-gutters>
        <v-col>
          {{ $t('pages.form.list_title') }}
        </v-col>
        <new-component-modal
          :parentId="this.parentId"
          :parentType="this.parentType"
          :withGroup="withGroup"
          @component-created="addToList"
        />
      </v-row>
    </v-card-title>
    <v-fade-transition hide-on-leave group>
      <v-card-text
        class="component"
        v-for="(component, i) in components" :key="component.id"
      >
        <component-card
          :parent-type="parentType"
          :id="`component-${component.id}`"
          :component="component"
          :components="components"
          @component-modified="componentModified(i, $event)"
          @deleted="removeFromList"
        >
          <template v-slot:actions>
            <v-btn
              :disabled="i <= 0"
              icon color="primary"
              @click="swapComponents(i, i - 1)"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <v-btn
              :disabled="i + 1 >= components.length"
              icon color="primary"
              @click="swapComponents(i, i + 1)"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </component-card>
      </v-card-text>
    </v-fade-transition>

    <v-card-text v-if="needPagination"
    >
      <v-row no-gutters v-if="fetchMixin.pagination" align="center" justify="space-between">
        <v-btn
          depressed color="primary"
          :disabled="fetchMixin.pagination.page >= fetchMixin.pagination.pageCount"
          @click="fetchMore"
        >
          {{ $t('common.action.show_more') }}
        </v-btn>

        <paginator
          class="pr-4"
          :pagination="fetchMixin.pagination"
          @update="updatePagination"
        />
      </v-row>
    </v-card-text>
  </v-card>

</template>

<script>
import Paginator from '@/components/util/paginator.vue';
import NewComponentModal from '@/components/forms/NewComponentModal.vue';
import ComponentCard from '@/components/forms/components/ComponentCard.vue';
import FlashUtils from '@/utils/flash.utils';
import FetchMixin from '@/mixins/fech.mixin';
import UpdateMixin from '@/mixins/update.mixin';
import ComponentService from '@/services/api/component.service';

export default {
  name: 'component-list',
  mixins: [FetchMixin, UpdateMixin],
  props: {
    parentId: { Type: Number, required: true },
    parentType: { Type: String, required: true },
    data: { Type: Array, default: null },
    withGroup: { type: Boolean, default: true },
  },
  components: {
    Paginator,
    NewComponentModal,
    ComponentCard,
  },
  data: () => ({
    components: [],
  }),
  computed: {
    needPagination() {
      return this.data === null;
    },
  },
  methods: {
    componentModified(index, component) {
      this.components[index] = component;
      this.components = [...this.components];
    },
    removeFromList(id) {
      this.components = this.components.filter((component) => component.id !== id);
    },
    addToList(component) {
      this.components = [...this.components, component];
    },
    swapComponents(i, j) {
      ComponentService.swapComponents(this.parentId, this.components[i].id, this.components[j].id)
        .then(() => {
          const components = [...this.components];

          const pTmp = components[i].position;
          components[i].position = components[j].position;
          components[j].position = pTmp;

          const tmp = components[i];
          components[i] = components[j];
          components[j] = tmp;

          this.components = components;
          FlashUtils.sendSuccess('Le swap à réussi');
        }).catch(() => {
          FlashUtils.sendError('Le swap à échoué !');
        });
    },

    fetchComponents() {
      const { page, size } = this.fetchMixin.pagination || { page: 1, size: 20 };
      return ComponentService.fetchComponents({ parentId: this.parentId, page, size });
    },
  },
  created() {
    this.fetchMixin.config = {
      dataField: 'components',
      fetch: 'fetchComponents',
    };

    if (this.data === null) {
      this.fetch();
    } else {
      this.components = this.data;
    }
  },
};
</script>

<style scoped>
.component {
  margin: 16px 0;
}

.component:first-child {
  margin-top: 0;
}

.component:last-child {
  margin-bottom: 0;
}
</style>
