<template>
  <div>
    <checkbox-repeating-group-form
      :value="checkboxRepeatingGroup"
    />
    <component-list
      :parentId="checkboxRepeatingGroup.id"
      parent-type="CHECK_BOX_REPEATING_GROUP"
      :data="checkboxRepeatingGroup.components"
      :withGroup="false"
    />
  </div>
</template>

<script>
import CheckboxRepeatingGroupForm from '@/components/forms/components/checkboxrepeatinggroup/CheckboxRepeatingGroupForm.vue';

export default {
  name: 'checkbox-repeating-group',
  components: {
    CheckboxRepeatingGroupForm,
    ComponentList: () => import('@/components/forms/components/ComponentList.vue'),
  },
  props: {
    checkboxRepeatingGroup: { type: Object, required: true },
  },
  data: () => ({
  }),
  methods: {

  },
};
</script>
